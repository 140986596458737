import React from "react";
import classNames from "classnames";

type Size = "medium" | "small";

type CommonProps = Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> &
  Partial<React.LinkHTMLAttributes<Omit<HTMLAnchorElement, "href">>>;

type ButtonProps = CommonProps & {
  children: string | React.ReactNode;
  component?: "button" | "link";
  size: Size | [Size, Size, Size];
  colour?: "cerise" | "navy" | "black";
  className?: string;
  disabled?: boolean;
  buttonType?: "primary" | "secondary";
  target?: string;
};

const baseSizes = {
  medium: "py-[14px] px-[30px] text-[16px]",
  small: "py-[8px] px-[20px] text-[16px]",
};

const prepareSizesForResponsive = (size: Size, prefix: string) => {
  return baseSizes[size]
    .split(" ")
    .map((el) => `${prefix}:${el}`)
    .join(" ");
};

/**
 * Multi-sized button component
 * @param size - Pass in a string for a single size, or an array of 3 strings for responsive sizes in the order of [mobile, tablet, desktop]
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      component = "button",
      href,
      colour = "cerise",
      size,
      className,
      buttonType = "primary",
      ...props
    },
    ref
  ) => {
    let responsiveSizes: string | null = null;

    const defaultStyles =
      "inline-block leading-[100%] font-bold duration-200 ease-in-out rounded-[100px] border-2 transition-[background-color,box-shadow,color,border-color]";
    const focusCommonStyles = {
      primary:
        "focus:ring-2 focus:outline-none focus:ring focus:ring-inset focus:ring-[#FFFFFF]",
      secondary: "focus:ring-2 focus:outline-none focus:ring focus:ring-inset",
    }[buttonType];
    const disabledStyles = {
      primary:
        "disabled:pointer-events-none disabled:bg-[#E3E3E5] disabled:text-mono-30 disabled:border-[#E3E3E5] disabled:user-select-none",
      secondary:
        "disabled:pointer-events-none disabled:bg-[#C8C8CC] disabled:text-mono-30 disabled:border-mono-30 disabled:user-select-none",
    }[buttonType];

    const commonStyles = classNames(
      defaultStyles,
      disabledStyles,
      focusCommonStyles
    );

    const colourStylesObject = {
      cerise: {
        primary:
          "text-[#FFFFFF] border-[#DA145E] bg-[#DA145E] hover:bg-[#99073E] hover:border-[#99073E] active:bg-[#DA145E] active:border-[#DA145E]",
        secondary: classNames(
          "text-cerise border-cerise bg-white",
          "hover:bg-[#D81C5F1A] hover:border-cerise",
          "active:bg-[#D81C5F33] active:ring-transparent active:border-cerise",
          "focus:ring-cerise focus:border-[#D81C5F33] focus:bg-[#D81C5F33]"
        ),
      },
      navy: {
        primary:
          "text-[#FFFFFF] border-pro-blue bg-pro-blue hover:bg-[#132A99] hover:border-[#132A99] active:bg-pro-blue active:border-pro-blue",
        secondary: classNames(
          "text-pro-blue border-pro-blue bg-white",
          "hover:bg-[#3350DB1A] hover:border-pro-blue",
          "active:bg-[#3350DB33] active:ring-transparent active:border-pro-blue",
          "focus:ring-pro-blue focus:border-[#3350DB33] focus:bg-[#3350DB33]"
        ),
      },
      black: {
        primary:
          "text-[#FFFFFF] border-black-rock bg-black-rock hover:bg-[#01010D] hover:border-[#01010D] active:bg-black-rock active:border-black-rock",
        secondary: classNames(
          "text-black-rock border-black-rock bg-white",
          "hover:bg-[#0202311A] hover:border-black-rock",
          "active:bg-[#02023133] active:ring-transparent active:border-black-rock",
          "focus:ring-black-rock focus:border-[#02023133] focus:bg-[#02023133]"
        ),
      },
    }[colour];
    const colourStyles = colourStylesObject
      ? colourStylesObject[buttonType]
      : null;

    if (!colourStyles) return null;

    if (typeof size === "object" && size.length === 3) {
      const mobileStyles = baseSizes[size[0]];
      const tabletStyles = prepareSizesForResponsive(size[1], "md");
      const desktopStyles = prepareSizesForResponsive(size[2], "lg");

      responsiveSizes = `${mobileStyles} ${tabletStyles} ${desktopStyles}`;
    } else {
      responsiveSizes = baseSizes[size];
    }

    const componentProps = {
      className: classNames(
        commonStyles,
        colourStyles,
        responsiveSizes,
        className
      ),
    };

    if (component === "button") {
      return (
        <button ref={ref} {...componentProps} {...props}>
          {children}
        </button>
      );
    }

    if (!href) {
      return null;
    }

    return (
      <a
        ref={ref as React.Ref<HTMLAnchorElement>}
        href={href}
        referrerPolicy={"strict-origin-when-cross-origin"}
        {...componentProps}
        {...props}
      >
        {children}
      </a>
    );
  }
);

export default Button;
