import { formattedDate, formattedTime, getTimeZoneString } from "lib/utils";



export type FormatDateEventCardOptions = {
  timezone?: string | string[];
  withDuration?: boolean;
  duration?: number;
};

// Known edge case with this - if you set a time late at night or early in the morning you may get an output that doesn't make sense:
// e.g. Sat 20 Apr 2024 / 23:30 (BST), 00:30 (CEST)
export function formatDateEventCard(
  date: Date,
  options: FormatDateEventCardOptions | undefined = {}
) {
  const { timezone, withDuration = false, duration = 0 } = options;
  const dateTimezone = Array.isArray(timezone) ? undefined : timezone;

  const dateFormatted = formattedDate(date, dateTimezone);
  const timeFormatted: string[] = [];
  const zoneArr = Array.isArray(timezone) ? timezone : [timezone];

  if (withDuration) {
    zoneArr.forEach((zone) => {
      const startTime = formattedTime(date, zone);
      const timeStamp = new Date(date).getTime();
      const endTime = formattedTime(
        new Date(timeStamp + duration * 60 * 1000),
        zone
      );

      timeFormatted.push(
        `${startTime} - ${endTime} (${getTimeZoneString(date, zone)})`
      );
    });
  } else {
    zoneArr.forEach((zone) => {
      timeFormatted.push(
        `${formattedTime(date, zone)} (${getTimeZoneString(date, zone)})`
      );
    });
  }

  return `${dateFormatted} / ${timeFormatted.join(", ")}`;
}

export function personImageLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality: number;
}): string {
  const url = new URL(src);
  const params = url.searchParams;

  params.set("auto", params.getAll("auto").join(",") || "format");
  params.set("fit", params.get("fit") || "max");
  params.set("crop", "faces,edges,entropy");
  params.set("w", params.get("w") || width.toString());
  params.set("ar", "1:1");

  if (quality) {
    params.set("q", quality.toString());
  }

  return url.href;
}
